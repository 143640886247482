<template>
  <div>
    <a-spin :spinning="confirmLoading">
      <HrProjectModal ref="realForm" @ok="submitCallback" @close="close" :disabled="disabled" />
      <footer-tool-bar>
        <a-col :md="24" :sm="24" v-if="reQuery.type == 'apply'">
          <!-- <a-button type="primary" ghost style="margin-right: 10px" @click="handleOk('save')">暂存表单</a-button> -->
          <a-button type="primary" ghost style="left: 10px" @click="handleOk('start')">启动</a-button>
        </a-col>
        <a-col :md="24" :sm="24" v-if="reQuery.type == 'deal'">
          <div style="display: flex">
            <div style="display: flex">
              <span style="width: 100px">审批意见:</span>
              <a-textarea
                style="margin-bottom: 20px"
                :autosize="{ minRows: 2, maxRows: 6 }"
                v-model="dealValue"
                placeholder="请输入审批意见"
              />
            </div>
            <div v-if="definiteBtn.button">
              <a-button
                style="margin-left: 10px"
                v-for="item in definiteBtn.button"
                :key="item.name"
                :type="item.value == 'true' ? 'primary' : ''"
                @click="definiteHandler(item)"
                >{{ item.name }}</a-button
              >
            </div>
            <div v-else>
              <a-button type="primary" style="margin-left: 10px" @click="compHandler(true)">同意</a-button>
              <a-button style="margin-left: 20px" @click="compHandler(false)">不同意</a-button>
            </div>
          </div>
        </a-col>
        <div class="lc_cz" v-if="reQuery.type === 'detail' && setFormPermission">
          <span class="table-page-search-submitButtons" style="float: right; padding: 10px 0">
            <a-col :md="24" :sm="24">
              <a-button
                type="primary"
                :loading="confirmLoading"
                ghost
                style="margin-right: 10px"
                @click="handleOk('save')"
                >修改表单</a-button
              >
            </a-col>
          </span>
        </div>
      </footer-tool-bar>
    </a-spin>
  </div>
</template>

<script>
import { baseUrl } from "@/api/api.js"
import FooterToolBar from '@/components/tools/FooterToolBar'
import { GetAtion, HttpAction } from "@/utils/http"
import HrProjectModal from '@/views/hrProjectManage/hrProject/modules/HrProjectModal'
import { mapGetters } from 'vuex'
import {
  setFormData,
  formSave,
  custFormSave,
  getFormData,
  getCustFormData,
  qdApply,
  completeHandler,
  getHandlerBtn,
  getProcessByActi,
} from '@/api/activitiApi'
export default {
  name: 'borrowRemandActiviti',
  inject: ['closeCurrent'],
  components: {
    HrProjectModal,
    FooterToolBar,
  },
  props: {
    /*全局禁用，可表示查看*/
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    /*管理员权限*/
    setFormPermission: {
      type: Boolean,
      default: false,
      required: false,
    },
    /*流程数据*/
    processData: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    reQuery: {
      type: Object,
      default: () => ({}),
    },
    /*是否新增*/
    isNew: { type: Boolean, default: false, required: false },
    /*是否处理流程*/
    task: { type: Boolean, default: false, required: false },
    /*审批历史数据 */
  },
  data() {
    return {
      title: '',
      visible: false,
      confirmLoading: false,
      loading: false,
      //审批意见
      dealValue: '',
      startType: 'save',
      //自定义按钮
      definiteBtn: {},
      url: {
        queryById: baseUrl + '/hr-server/hrProject/queryById',
      },
    }
  },
  created() {},
  mounted() {
    if (this.task) {
      this.initHandlerBtn()
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: true,
      handler(val) {
        console.log('disabled-processData-reQuery：', this.disabled, this.processData, this.reQuery)
        this.edit(this.reQuery)
      },
    },
  },
  methods: {
    //自定义按钮审批
    definiteHandler(item) {
      let flag = item.value == 'true'
      this.compHandler(flag)
    },
    //获取自定义按钮
    initHandlerBtn() {
      let param = {
        instanceId: this.reQuery.processInstanceId,
        definitionId: this.reQuery.definitionId,
      }
      getHandlerBtn(param).then((res) => {
        if (res.success) {
          this.definiteBtn = res.result
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //获取流程数据---编辑/查看/启动
    getProc(activitiId) {
      return getProcessByActi(activitiId).then((res) => {
        if (res.success) {
          return res.result
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    // 办理--处理
    compHandler(flag) {
      let that = this
      this.confirmLoading = true
      // 当表单不能修改时，走正常审核提交接口
      // if (this.disabled) {
      //   this.setCompHandler(flag)
      // } else {
        // 当表单可以修改时，需要先保存表单再走审核接口
        //在这里调用保存表单的接口
        this.$refs.realForm.handleOk((valid) => {
          if (valid) {
            that.setCompHandler(flag)
          }
        }, flag)
      // }
    },

    setCompHandler(flag) {
      this.dealValue = this.dealValue ? this.dealValue : flag ? '同意' : '不同意'
      completeHandler({
        taskId: this.reQuery.taskId,
        instanceId: this.reQuery.processInstanceId,
        variables: {
          comment: this.dealValue,
          pass: flag,
        },
      }).then((res) => {
        if (res.success) {
          this.$message.success('审核成功')
          this.confirmLoading = false
          // this.closeCurrent()
        } else {
          this.$message.error('审核失败')
        }
      })
    },
    add() {
      this.$nextTick(() => {
        this.$refs.realForm.add()
      })
    },
    edit(record) {
      this.$nextTick(() => {
        /** 
         * @obj 表单参数
         * 各自处理各自不同的表单情况
         * */ 
        const { category, processName, definitionId, projectId, pid, activitiType } = this.reQuery
        let obj = {
          procDefId: definitionId,
          processName,
          domain: {},
          
          // , category
        }
        if (record.businessKey) {
          GetAtion(this.url.queryById, { id: record.businessKey }).then((res) => {
            if (res.success) {
              obj.domain = { ...res.result }
              this.$refs.realForm.edit({ ...obj })
            }
          })
        } else {
          this.$refs.realForm.add(obj)
        }
      })
    },
    close() {
      this.confirmLoading = false
    },
    handleOk(type) {
      this.startType = type
      this.confirmLoading = true
      this.$refs.realForm.handleOk()
    },
    submitCallback(obj) {
      if (this.startType == 'start' && obj.activitiId) {
        this.getProc(obj.activitiId)
          .then((res) => {
            this.againApply(res)
          })
          .catch((err) => {
            this.confirmLoading = false
          })
      } else if (this.startType == 'save') {
        this.confirmLoading = false
        this.closeCurrent()
        this.$message.success('暂存成功')
      }
    },
    //重新提交(启动)
    againApply(record) {
      if (!record.businessKey) {
        this.$message.warning('businessKey不存在,找管理员')
        this.confirmLoading = false
        return
      }
      let variables = {}
      variables = record.variables ? JSON.parse(record.variables) : {type:''};
      qdApply({
        username: this.userInfo.username,
        definitionId: record.definitionId,
        processName: record.processName,
        id: record.businessKey,
        key: record.businessKey,
        variables: variables,
        category: record.category,
      })
        .then((res) => {
          if (res.success) {
            this.$message.success('任务启动成功')
            this.closeCurrent()
          } else {
            this.$message.error('任务启动失败')
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('任务启动失败')
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
  },
}
</script>

<style scoped>
</style>