<template>
  <!-- <j-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"> -->
    
    <a-spin :spinning="confirmLoading">
      <a-form-model ref="form" :model="model" :rules="validatorRules">
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="name" label="项目名称">
          <a-input placeholder="请输入项目名称" v-model="model.name" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="chargeName" label="负责人">
          <j-select-multi-user :multiple="false" v-model="model.chargeName" @select="selectUser" :query-config="selectUserQueryConfig"/>
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="hrProjectMemberList" label="项目成员">
          <j-select-multi-user v-model="hrProjectMember" @select="selectProjectMember" :query-config="selectUserQueryConfig"/>
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="budgetAmount" label="预算金额">
          <a-input-number style="width: 100%" :min="0" placeholder="请输入预算金额" v-model="model.budgetAmount" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="planStartTime" label="计划开始时间">
          <a-date-picker style="width: 100%" showTime valueFormat='YYYY-MM-DD HH:mm:ss' v-model="model.planStartTime" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="planEndTime" label="	计划结束时间">
          <a-date-picker style="width: 100%" showTime valueFormat='YYYY-MM-DD HH:mm:ss' v-model="model.planEndTime" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="startTime" label="实际开始时间">
          <a-date-picker style="width: 100%" showTime valueFormat='YYYY-MM-DD HH:mm:ss' v-model="model.startTime" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="endTime" label="实际结束时间">
          <a-date-picker style="width: 100%" showTime valueFormat='YYYY-MM-DD HH:mm:ss' v-model="model.endTime" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="accepterName" label="验收人">
          <j-select-multi-user :multiple="false" v-model="model.accepterName" @select="accepterName" :query-config="selectUserQueryConfig"/>
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="remark" label="备注">
          <a-textarea
            v-model="model.remark"
            placeholder="请输入备注"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  <!-- </j-modal> -->
</template>

<script>
  import { baseUrl } from "@/api/api.js"
  import { GetAtion, HttpAction } from "@/utils/http"

  export default {
    name: "HrProjectModal",
    components: {},
    data () {
      return {
        title:"操作",
        visible: false,
        model: {
          hrProjectMemberList: []
        },
        // 选择用户查询条件配置
        selectUserQueryConfig: [
          {key: 'phone', label: '电话'},
        ],
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },

        confirmLoading: false,
        validatorRules:{
          name: [{ required: true, message: '请输入项目名称!' }],
          chargeName: [{ required: true, message: '请选择负责人!' }],
          planStartTime: [{ required: true, message: '请选择计划开始时间!' }],
          planEndTime: [{ required: true, message: '请选择计划结束时间!' }],
        },
        url: {
          add: baseUrl + "/hr-server/hrProject/add",
          edit: baseUrl + "/hr-server/hrProject/edit",
        },
        hrProjectMember: '',
        processName: '',
        procDefId: '',
      }
    },
    created () {
    },
    watch: {
    },
    computed: {
    },
    methods: {
      selectUser(value) {
        if (value?.length) {
          this.model.chargeUid = value[0].id
        } else {
          this.model.chargeUid = ''
        }
      },
      accepterName(value) {
        if (value?.length) {
          this.model.accepterName = value[0].id
        } else {
          this.model.accepterName = ''
        }
      },
      selectProjectMember(value) {
        if (value?.length) {
          this.model.hrProjectMemberList = value.map(item => {
            for (let index = 0; index < this.model.hrProjectMemberList.length; index++) {
              const element = this.model.hrProjectMemberList[index];
              if (element.memberUid === item.id) {
                return element;
              }
            }
            return {
              memberName: item.username,
              memberUid: item.id,
              projectId: this.model.id || ''
            }
          })
        } else {
          this.model.hrProjectMemberList = []
        }
      },
      add (obj) {
        //初始化默认值
        this.edit(obj);
      },
      edit (record) {
        this.procDefId = record.procDefId
        this.processName = record.processName
        this.model = Object.assign({}, record.domain);
        if (this.model.hrProjectMemberList && this.model.hrProjectMemberList.length) {
          this.hrProjectMember = this.model.hrProjectMemberList.map(item => item.memberName).join(',')
        } else {
          this.model.hrProjectMemberList = []
        }
        this.visible = true;
      },
      close () {
        this.$emit('close');
        this.visible = false;
        this.$refs.form.clearValidate();
      },
      handleOk (cb) {
        const that = this;
        // 触发表单验证
         this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
              method = 'put';
            }
            HttpAction(httpurl,{ 
              domain: this.model,
              processName: this.processName,
              procDefId: this.procDefId
            },method).then((res)=>{
              if(res.success) {
                if (method == 'post') {
                  if (res.result) {
                    that.model.activitiId = res.result
                    that.$emit('ok', this.model)
                  }
                } else {
                  that.$emit('ok', this.model)
                }
                typeof cb === 'function' &&  cb(valid);
              } else {
                that.$emit('close')
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
          }else{
             return false;
          }
        })
      },
      handleCancel () {
        this.close()
      },
    }
  }
</script>

<style lang="less" scoped>
.select_box {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>